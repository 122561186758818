<div
  class="op-sidebar--body"
>
  <op-sidemenu
    *ngIf="(boardOptions$ | async) as items"
    [items]="items"
  >
  </op-sidemenu>
</div>

<div class="op-sidebar--footer">
  <button
    *ngIf="canCreateBoards$ | async"
    class="button -alt-highlight"
    (click)="showNewBoardModal()"
    [title]="text.create_new_board"
  >
    <span class="spot-icon spot-icon_add"></span>
    <span [textContent]="text.board"></span>
  </button>
</div>
