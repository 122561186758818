<form
  class="op-modal"
  (submit)="applyAndClose($event)"
>
  <op-modal-header (close)="closeMe($event)">{{text.title}}</op-modal-header>

  <div class="op-modal--body">
    <fieldset class="form--fieldset">
      <legend [textContent]="text.page"></legend>
      <div class="form--field">
        <label
            for="selected-page"
            [textContent]="text.page"
            class="form--label hidden-for-sighted">
        </label>
        <div class="form--field-container">
          <div class="form--select-container">
            <input
                #selectedPageInput
                tabindex="2"
                type="text"
                id="selected-page"
                name="selected-page"
                [value]="selectedPage"
                (change)="selectedPage = $event.target.value"
                class="form--select" />
          </div>
        </div>
        <p [textContent]="text.hint"></p>
        <p><kbd>project-identifier:my-wiki-page</kbd></p>
      </div>
    </fieldset>
  </div>
  <div class="op-modal--footer">
    <button
      class="op-modal--cancel-button button"
      (click)="closeMe($event)"
      tabindex="4"
      [textContent]="text.button_cancel"
      [attr.title]="text.button_cancel"
    ></button>
    <button
      class="op-modal--submit-button button -highlight"
      (click)="applyAndClose($event)"
      tabindex="3"
      [textContent]="text.button_save"
      [attr.title]="text.button_save"
    ></button>
  </div>
</form>
