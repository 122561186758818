<div
        class="work-packages--details op-empty-state hidden-for-mobile"
        data-qa-selector="op-empty-state"
>
    <div class="work-packages-tab-view--overflow op-empty-state--content">
      <ng-container *ngIf="(loading$ | async) === false; else loading">
        <ng-container *ngIf="(hasNotifications$ | async) as hasNotifications; else noNotification">
          <img [src]="image.no_selection" class="op-empty-state--content-image"/>
          <p class="op-empty-state--content-text">
            <span>{{ text.no_selection }}</span>
          </p>
        </ng-container>
        <ng-template #noNotification>
          <img [src]="image.no_notification" class="op-empty-state--content-image"/>
          <p class="op-empty-state--content-text">
            <span [textContent]="noNotificationText(hasNotifications, (totalCount$ | async))"></span>
          </p>
        </ng-template>

      </ng-container>
      <ng-template #loading>
        <img [src]="image.loading" class="op-empty-state--content-image"/>
      </ng-template>
    </div>
</div>
