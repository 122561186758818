<ng-container *ngIf="!hidden">
  <label class="op-form-field--label-wrap">
    <div class="op-form-field--label">
      <span
        *ngIf="showErrorMessage"
        class="Hidden for sighted"
      >Invalid</span>
      {{ label }}
      <span *ngIf="required" class="op-form-field--label-indicator">*</span>
      <attribute-help-text
        [attribute]="helpTextAttribute"
        [attributeScope]="helpTextAttributeScope"
      ></attribute-help-text>
    </div>

    <ng-container *ngIf="!noWrapLabel">
      <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
    </ng-container>
  </label>

  <ng-container *ngIf="noWrapLabel">
    <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
  </ng-container>

  <div class="op-form-field--help-text">
    <ng-content select="[slot=help-text]"></ng-content>
  </div>

  <div
    class="op-form-field--errors"
    *ngIf="showErrorMessage"
    [id]="errorsID"
  >
    <ng-content select="[slot=errors]"></ng-content>
  </div>
</ng-container>

<ng-template #inputTemplate>
  <div
    class="op-form-field--description"
    [id]="descriptionID"
  >
    <ng-content select="[slot=description]"></ng-content>
  </div>

  <div
    class="op-form-field--input"
    [attr.aria-describedby]="describedByID"
  >
    <ng-content select="[slot=input]"></ng-content>
  </div>
</ng-template>
