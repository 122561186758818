<div
  class="op-enterprise-banner"
  data-qa-selector="op-enterprise-banner"
>
  <div class="op-toast -ee-upsale"
       [ngClass]="{'-left-margin': leftMargin }">
    <div class="op-toast--content">
      <p class="-bold" [textContent]="text.enterpriseFeature"></p>
      <p [textContent]="textMessage"></p>
      <a [href]="link"
         target='blank'
         [textContent]="linkMessage"></a>
    </div>
  </div>
</div>