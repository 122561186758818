<div
  class="op-modal op-modal_autoheight confirm-form-submit--modal loading-indicator--location"
  [ngClass]="{'-highlight -danger-zone' : dangerHighlighting }"
  data-indicator-name="modal"
>
  <op-modal-header (close)="closeMe($event)">{{text.title}}</op-modal-header>

  <div class="op-modal--body">
    <p>
      <span [textContent]="text.text"></span>
      <br/>
      <span *ngFor="let data of passedData">
        <br/>
        <strong>{{data}}</strong>
      </span>
    </p>
  </div>

  <div class="op-modal--footer">
    <button class="confirm-form-submit--continue button"
            [ngClass]="dangerHighlighting ? '-danger': '-highlight'"
            (click)="confirmAndClose($event)"
            [textContent]="text.button_continue"
    ></button>
    <button class="confirm-form-submit--cancel button"
            (click)="closeMe($event)"
            [textContent]="text.button_cancel"
    ></button>
  </div>
</div>
