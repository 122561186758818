<form
  class="op-modal op-modal_autoheight form -vertical confirm-form-submit--modal password-confirm-dialog--modal loading-indicator--location"
  data-indicator-name="modal"
  (submit)="confirmAndClose($event)"
>
  <op-modal-header (close)="closeMe($event)">
    {{text.title}}
  </op-modal-header>

  <div class="op-modal--body request-for-confirmation--form form">
    <div class="form--field -required">
      <label class="form--label"
             for="request_for_confirmation_password"
             [attr.aria-label]="text.field_description"
             [textContent]="text.password">
      </label>
      <div class="form--field-container">
        <div class="form--text-field-container">
          <input type="password"
                 #passwordConfirmationField
                 [(ngModel)]="password_confirmation"
                 id="request_for_confirmation_password"
                 name="request_for_confirmation_password"
                 class="form--text-field">
        </div>
      </div>
      <div class="form--field-instructions" [textContent]="text.field_description"></div>
    </div>
  </div>

  <div class="op-modal--footer">
    <button
      class="confirm-form-submit--continue button -highlight"
      [textContent]="text.confirm_button"
      [disabled]="!passwordValuePresent()">
    ></button>
  </div>
</form>
