<form
  class="op-modal "
  (submit)="applyAndClose($event)"
>
  <op-modal-header (close)="closeMe($event)">{{text.title}}</op-modal-header>

  <div class="op-modal--body form">
    <fieldset class="form--fieldset">
      <legend [textContent]="text.page"></legend>
      <div class="form--field">
        <label
            for="selected-page"
            [textContent]="text.page"
            class="form--label hidden-for-sighted">
        </label>
        <div class="form--field-container">
          <div class="form--select-container">
            <input
                #selectedPageInput
                tabindex="2"
                type="text"
                id="selected-page"
                name="selected-page"
                [value]="selectedPage"
                (change)="selectedPage = $event.target.value"
                class="form--select"
                placeholder="my-project:my-page" />
          </div>
        </div>
      </div>
      <div class="form--field">
        <div class="form--field-container">
          <label class="form--label-with-check-box">
            <div class="form--check-box-container">
              <input
                      #parentIncludedInput
                      tabindex="3"
                      type="checkbox"
                      id="include-parent"
                      name="include-parent"
                      [ngModel]="selectedIncludeParent"
                      (ngModelChange)="updateIncludeParent($event)"
                      class="form--check-box" />
            </div>
            {{ text.include_parent }}
          </label>
        </div>
      </div>
      <p [textContent]="text.hint"></p>
    </fieldset>
  </div>
  <div class="op-modal--footer">
    <button
      class="op-modal--cancel-button button"
      (click)="closeMe($event)"
      type="button"
      tabindex="4"
      [textContent]="text.button_cancel"
    ></button>
    <button
      class="op-modal--submit-button button -highlight"
      tabindex="3"
      [textContent]="text.button_save"
    ></button>
  </div>
</form>
