<div class="op-authoring">
  <span
    *ngIf="showAuthorAsLink"
    [textContent]="authorName"
  ></span>
  <a
    *ngIf="!showAuthorAsLink"
    [attr.href]="authorLink"
    [textContent]="authorName"
  ></a>

  <span
    class="op-authoring--timestamp"
    [textContent]="timeago"
    [attr.title]="time"
  ></span>
</div>
