<div class="work-package--attachments--files" *ngIf="resource">
  <ul class="form--selected-value--list"
      *ngFor="let attachment of $attachments | async; trackBy:trackByFileName; let i = index;">
    <op-attachment-list-item [attachment]="attachment"
                             [resource]="resource"
                             [index]="i"
                             (removeAttachment)="removeAttachment(attachment)">
    </op-attachment-list-item>
  </ul>
</div>
