<ng-select
  appendTo=".op-modal-overlay"
  [formControl]="roleControl"
  [typeahead]="input$"
  [items]="items$ | async"
  [virtualScroll]="true"
  [clearable]="true"
  [clearOnBackspace]="false"
  [clearSearchOnAdd]="false"
  bindLabel="name"
  autofocus
  #ngselect
>
  <!--Selectable option-->
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <div [ngOptionHighlight]="search" class="ng-option-label ellipsis">{{ item.name }}</div>
  </ng-template>

  <!--Nothing found -->
  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled">
      {{ text.noRolesFound }}
    </div>
  </ng-template>
</ng-select>
