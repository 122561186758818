<li
  class="spot-list--item"
  *ngFor="let project of projects; index as i; first as isFirst; last as isLast"
>
  <label
    class="spot-list--item-action op-project-list--item-action"
    [ngClass]="{ 'spot-list--item-action_disabled': isDisabled(project) }"
  >
    <spot-tooltip
      class="op-project-list--tooltip"
      [alignment]="getAlignment(project, isFirst, isLast)"
      [disabled]="!isDisabled(project)"
      [dark]="true"
    >
      <ng-container slot="trigger">
        <spot-checkbox
          *ngFor="let checked of [isChecked(project.href)]"
          [checked]="checked || (includeSubprojects && parentChecked)"
          (change)="changeSelected(project)"
          [disabled]="isDisabled(project)"
          [attr.data-qa-project-include-id]="project.id"
          [attr.data-qa-project-include-checked]="(checked || (includeSubprojects && parentChecked))  ? 1 : 0"
        ></spot-checkbox>
        <div
          class="spot-list--item-title op-project-list--item-title"
          [opSearchHighlight]="searchText" 
          [title]="project.name"
        >{{ project.name }}</div>
      </ng-container>

      <p
        slot="body"
        class="spot-body-small"
      >
        <span *ngIf="includeSubprojects && parentChecked">{{ text.include_all_selected }}</span>
        <span *ngIf="project.href === currentProjectHref">{{ text.current_project }}</span>
      </p>
    </spot-tooltip>
  </label>

  <ul
    *ngIf="project.children.length"
    op-project-list
    [projects]="project.children"
    [selected]="selected"
    [includeSubprojects]="includeSubprojects"
    [parentChecked]="parentChecked || isChecked(project.href)"
    [searchText]="searchText"
    (update)="updateSelected($event)"
  ></ul>
</li>
