<h1>Buttons & Links</h1>
<section class="space-children">
  <button class="spot-button">Default</button>
  <button class="spot-button">
    <span>Default</span>
    <span class="spot-icon spot-icon_bell"></span>
  </button>
  <button class="spot-button"><span class="spot-icon spot-icon_bell"></span></button>
</section>

<section class="space-children">
  <button class="spot-button spot-button_main">Main</button>
  <button class="spot-button spot-button_main">
    <span>Main</span>
    <span class="spot-icon spot-icon_bell"></span>
  </button>
  <button class="spot-button spot-button_main"><span class="spot-icon spot-icon_bell"></span></button>
</section>

<section class="space-children">
  <button class="spot-button spot-button_accent">Accent</button>
  <button class="spot-button spot-button_accent">
    <span>Accent</span>
    <span class="spot-icon spot-icon_bell"></span>
  </button>
  <button class="spot-button spot-button_accent"><span class="spot-icon spot-icon_bell"></span></button>
</section>

<section class="space-children">
  <button class="spot-button spot-button_danger">Danger</button>
  <button class="spot-button spot-button_danger">
    <span>Danger</span>
    <span class="spot-icon spot-icon_bell"></span>
  </button>
  <button class="spot-button spot-button_danger"><span class="spot-icon spot-icon_bell"></span></button>
</section>

<section class="space-children">
  <button class="spot-button" disabled>Disabled</button>
  <button class="spot-button" disabled>
    <span>Disabled</span>
    <span class="spot-icon spot-icon_bell"></span>
  </button>
  <button class="spot-button" disabled><span class="spot-icon spot-icon_bell"></span></button>
</section>

<section class="space-children">
  <button class="spot-button spot-button_outlined">Default</button>
  <button class="spot-button spot-button_outlined spot-button_main">Main</button>
  <button class="spot-button spot-button_outlined spot-button_accent">Accent</button>
  <button class="spot-button spot-button_outlined spot-button_danger">Danger</button>
  <button class="spot-button spot-button_outlined" disabled>Disabled</button>
</section>

<section class="space-children">
  <button class="spot-link">Default link</button>
  <a class="spot-link" href="#">
    <span>Default Link</span>
    <span class="spot-icon spot-icon_bell"></span>
  </a>
  <button class="spot-link"><span class="spot-icon spot-icon_bell"></span></button>
</section>

<section class="space-children">
  <button class="spot-link spot-link_danger">Danger Link</button>
  <a class="spot-link spot-link_danger" href="#">
    <span>Danger Link</span>
    <span class="spot-icon spot-icon_bell"></span>
  </a>
  <button class="spot-link spot-link_danger"><span class="spot-icon spot-icon_bell"></span></button>
</section>

<h1>Toggles</h1>

<spot-toggle
  [options]="toggleOptions"
  [(ngModel)]="toggleValue"
></spot-toggle>
Currently selected value is {{ toggleValue }}

<h1>Checkbox</h1>

<label>
  <spot-checkbox></spot-checkbox> Unchecked
</label>

<label>
  <spot-checkbox [checked]="true"></spot-checkbox> Checked
</label>

<label>
  <spot-checkbox [disabled]="true"></spot-checkbox> Disabled
</label>

<label>
  <spot-checkbox
    [checked]="true"
    [disabled]="true"
  ></spot-checkbox> Disabled checked
</label>

<label>
  <spot-checkbox [(ngModel)]="checkboxValue"></spot-checkbox>
  Currently {{ checkboxValueString() }}
</label>

<label>
  <spot-checkbox
    [(ngModel)]="checkboxValue"
    [disabled]="true"
    name="checkbox-name"
  ></spot-checkbox>
  Currently {{ checkboxValueString() }}
</label>

<h1>Lists</h1>

<ul class="spot-list">
  <li class="spot-list--item">
    <label class="spot-list--item-action">
      <spot-checkbox [(ngModel)]="listCheckboxValue"></spot-checkbox>
      <div class="spot-list--item-title">Checkbox item</div>
    </label>
  </li>
  <li class="spot-list--item">
    <label class="spot-list--item-action">
      <spot-checkbox [(ngModel)]="listCheckboxValue"></spot-checkbox>
      <div class="spot-list--item-title">Checkbox item with children</div>
    </label>
    <ul class="spot-list">
      <li class="spot-list--item">
        <label class="spot-list--item-action">
          <spot-checkbox [(ngModel)]="listCheckboxValue"></spot-checkbox>
          <div class="spot-list--item-title">Child checkbox item</div>
        </label>
        <ul class="spot-list">
          <li class="spot-list--item">
            <label class="spot-list--item-action">
              <spot-checkbox [(ngModel)]="listCheckboxValue"></spot-checkbox>
              <div class="spot-list--item-title">Secon level child</div>
            </label>
          </li>
        </ul>
      </li>
      <li class="spot-list--item">
        <label class="spot-list--item-action">
          <spot-checkbox [(ngModel)]="listCheckboxValue"></spot-checkbox>
          <div class="spot-list--item-title">Second child checkbox item</div>
        </label>
      </li>
      <li class="spot-list--item">
        <label class="spot-list--item-action spot-list--item-action_disabled">
          <spot-checkbox
            [(ngModel)]="listCheckboxValue"
            [disabled]="true"
          ></spot-checkbox>
          <div class="spot-list--item-title">Disabled item</div>
        </label>
      </li>
    </ul>
  </li>
</ul>

<h1>Filter Chip</h1>

<section class="space-children">
  <spot-filter-chip
    title="Default chip"
    (remove)="onRemoveChip()"
  ></spot-filter-chip>

  <spot-filter-chip
    title="Unremovable chip"
    [removable]="false"
  ></spot-filter-chip>

  <spot-filter-chip
    title="Default chip with icon"
    icon="bell"
    (remove)="onRemoveChip()"
  ></spot-filter-chip>

  <spot-filter-chip
    icon="bell"
    title="Unremovable chip with icon"
    [removable]="false"
  ></spot-filter-chip>
</section>

<section class="space-children">
  <spot-filter-chip
    title="Default chip"
    (remove)="onRemoveChip()"
    [disabled]="true"
  ></spot-filter-chip>

  <spot-filter-chip
    title="Unremovable chip"
    [removable]="false"
    [disabled]="true"
  ></spot-filter-chip>

  <spot-filter-chip
    title="Default chip with icon"
    icon="bell"
    (remove)="onRemoveChip()"
    [disabled]="true"
  ></spot-filter-chip>

  <spot-filter-chip
    icon="bell"
    title="Unremovable chip with icon"
    [removable]="false"
    [disabled]="true"
  ></spot-filter-chip>
</section>

<h1>Text Field</h1>

<section class="space-children">
  <input
    class="spot-text-field"
    placeholder="Placeholder value"
  />

  <input
    class="spot-text-field"
    value="Test value"
  />

  <spot-text-field
    placeholder="Placeholder value"
    value="Test value"
    name="textfield-name"
  ></spot-text-field>

  <spot-text-field
    placeholder="Placeholder value"
    [(ngModel)]="textFieldValue"
  ></spot-text-field>

  <spot-text-field
    placeholder="Placeholder value"
    [(ngModel)]="textFieldValue"
  >
    <span
      slot="after"
      class="spot-icon spot-icon_bell"
    ></span>
  </spot-text-field>

  <spot-text-field
    placeholder="Placeholder value"
    [(ngModel)]="textFieldValue"
  >
    <span
      slot="before"
      class="spot-icon spot-icon_bell"
    ></span>
    <span
      slot="after"
      class="spot-icon spot-icon_bell"
    ></span>
  </spot-text-field>

  <spot-text-field
    placeholder="Placeholder value"
    [(ngModel)]="textFieldValue"
  >
    <span
      slot="before"
      class="spot-icon spot-icon_bell"
    ></span>
  </spot-text-field>
</section>

<h1>Drop Modal</h1>

<select [(ngModel)]="dropModalAlignment">
  <option>left-top</option>
  <option>left-center</option>
  <option>left-bottom</option>
  <option>top-left</option>
  <option>top-center</option>
  <option>top-right</option>
  <option>right-top</option>
  <option>right-center</option>
  <option>right-bottom</option>
  <option>bottom-left</option>
  <option>bottom-center</option>
  <option>bottom-right</option>
</select>

<spot-drop-modal
  [open]="dropModalOpen"
  [alignment]="dropModalAlignment"
  (closed)="dropModalOpen = false"
>
  <button
    slot="trigger"
    type="button"
    class="spot-button spot-button_main"
    (click)="dropModalOpen = !dropModalOpen"
  >Open Modal</button>
  <ng-container slot="body">
    <div class="spot-container">
      <h1 class="spot-header-small">Test</h1>

      <spot-text-field
        placeholder="Placeholder value"
        [(ngModel)]="textFieldValue"
      >
        <span
          slot="before"
          class="spot-icon spot-icon_bell"
        ></span>
      </spot-text-field>
    </div>
  </ng-container>
</spot-drop-modal>

<h1>Tooltip</h1>

<section class="space-children">
  <select [(ngModel)]="tooltipAlignment">
    <option>left-top</option>
    <option>left-center</option>
    <option>left-bottom</option>
    <option>top-left</option>
    <option>top-center</option>
    <option>top-right</option>
    <option>right-top</option>
    <option>right-center</option>
    <option>right-bottom</option>
    <option>bottom-left</option>
    <option>bottom-center</option>
    <option>bottom-right</option>
  </select>
  <spot-tooltip [alignment]="tooltipAlignment">
    <ng-container slot="trigger">Trigger</ng-container>
    <p
      slot="body"
      class="spot-body-small"
    >Body</p>
  </spot-tooltip>

  <spot-tooltip [alignment]="tooltipAlignment">
    <ng-container slot="trigger">Trigger</ng-container>
    <ng-container slot="body">
      <p class="spot-body-small">Body with multiple paragraphs coming in here</p>
      <p class="spot-body-small">This is the second paragraph</p>
    </ng-container>
  </spot-tooltip>

  <spot-tooltip
    [alignment]="tooltipAlignment"
    [dark]="true"
  >
    <ng-container slot="trigger">Dark tooltip trigger</ng-container>
    <ng-container slot="body">
      <p class="spot-body-small">This is a dark tooltip</p>
    </ng-container>
  </spot-tooltip>
</section>

<h1>Action Bar</h1>

<div class="spot-action-bar">
  <div class="spot-action-bar--left">
    <button class="spot-button spot-button_outlined spot-button_main">
      Independent action
    </button>
  </div>
  <div class="spot-action-bar--right">
    <button class="spot-button spot-button_outlined spot-button_main">
      Some Action
    </button>
    <button class="spot-button spot-button_main">
      Another Action
    </button>
  </div>
</div>

<style>
  #content-wrapper {
    background-color: #eee;
  }
</style>
