<div class="op-tile-block">
  <button
    *ngFor="let tile of tiles"
    class="op-tile-block--tile button"
    data-qa-selector="op-tile-block"
    type="button"
    [disabled]="tile.disabled || disable"
    (click)="created(tile.attribute)"
  >
    <img [src]="tile.image" class="op-tile-block--image"/>
    <div>
      <span
        data-qa-selector="op-tile-block-title"
        class="op-tile-block--title"
      >{{ tile.text }}</span>
      <p class="op-tile-block--description" [textContent]="tile.description"></p>
    </div>
  </button>
</div>