<div
  *ngIf="resource.canAddAttachments"
  class="wp-attachment-upload hide-when-print"
  (drop)="onDropFiles($event)"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  [attr.aria-label]="text.uploadLabel">
  <input #hiddenFileInput
         type="file"
         id="attachment_files"
         name="attachment_files"
         (change)="onFilePickerChanged()"
         hidden
         multiple/>
  <button
    type="button"
    class="work-package--attachments--drop-box"
    (click)="triggerFileInput($event)"
    [class.-dragging]="draggingOver"
  >
    <span class="work-package--attachments--label">
      <op-icon icon-classes="icon-attachment"></op-icon>
      <label for="attachment_files">
        {{ text.dropFiles }} <br>
        {{ text.dropFilesHint }}
      </label>
    </span>
  </button>
</div>
